import React, { useState, useEffect, useRef, useCallback } from 'react'
import {
  Card,
  Table,
  Typography,
  Button,
  Modal,
  Form,
  Input,
  DatePicker,
  Tag,
  Select,
  message,
  Tooltip,
  Tabs,
} from 'antd'
import {
  FileAddOutlined,
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
  LinkOutlined,
  ExportOutlined,
} from '@ant-design/icons'

import moment from 'moment'
import {
  StatusOptions,
  SumarryColumns,
  StatusOptionsForAdmin,
  SumarryColumnsCategory,
  SumarryColumnsEdited,
} from '../../helpers/enums'
import dayjs from 'dayjs'
import * as changeCase from 'change-case'
import { saveAs } from 'file-saver'
import _ from 'underscore'
import "react-toastify/dist/ReactToastify.css";
import { HiOutlineDocumentText } from 'react-icons/hi'

import 'react-quill/dist/quill.snow.css';
import { CopilotPopup } from "@copilotkit/react-ui";
import { useCopilotReadable } from "@copilotkit/react-core";


import { MdBookmarkAdded } from 'react-icons/md'
import { CiBookmarkPlus } from 'react-icons/ci'
import { FaBookmark, FaRegBookmark } from 'react-icons/fa'
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ImageUpload from './ImageUpload'
import { gapi } from 'gapi-script'
const { Title } = Typography
const { RangePicker } = DatePicker
const { TextArea } = Input
const { Option } = Select

const config = {
  rules: [
    {
      type: 'object',
      required: true,
      message: 'Please select date!',
    },
  ],
}

const ArticleHistory = () => {
  const rangePickerRef = useRef(null)
  const apiUrl = process.env.REACT_APP_API_URL
  const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID
  const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY
  const SCOPES = process.env.REACT_APP_GOOGLE_SCOPES
  const [messageApi, contextHolder] = message.useMessage()
  const [form] = Form.useForm()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [showDeleteConfirmation, setShowDeleteConfimation] = useState(false)
  const [showPublishedArticleLink, setShowPublishedArticleLink] = useState(
    false,
  )
  const [showReviewersComment, setShowReviewersComment] = useState(false)
  const [reviewersComment, setReviewersComment] = useState('')
  const [articleUrl, setArticleUrl] = useState('')
  const [isEdit, setIsEdit] = useState(false)
  const [showInfoGraphics, setShowInfoGraphics] = useState(false)
  const [isLoadingAddArticle, setIsLoadingAddArticle] = useState(false)
  const [isLoadingDeleteArticle, setIsLoadingDeleteArticle] = useState(false)
  const [isEverythingUnique, setIsEverythingUnique] = useState(true)
  const [hour, setHour] = useState("")
  const [selectedCategory, setSelectedCategory] = useState('Long-form')
  const [selectStoryType, setSelectedStory] = useState([])
  const [activeTab, setActiveTab] = useState('All')
  const [articlesForUser, setArticlesForUser] = useState({
    isLoading: false,
    articleList: [],
  })
  const [selectedAuthor, setSelectedAuthor] = useState(null)
  const [filters, setFilters] = useState({
    sort: 'created',
    dateRange: [
      dayjs(moment().subtract(2, 'months')),
      dayjs(moment()),
    ],
  });




  const userDetails = JSON.parse(localStorage.getItem('auth'))
  console.log("UserDetails",userDetails)
  const [filterByBookmark, setFilterByBookmark] = useState(false);
  const [bookmarkedStates, setBookmarkedStates] = useState({});
  let isCopyEditor = userDetails?.roles?.includes('copy-editor')
  let isCreative = userDetails?.roles?.includes('designer')
  let tomorrow = moment()?.format('YYYY-MM-DD')
  let today = moment().subtract(2, 'months').format('YYYY-MM-DD')
  let filteredArticleList = filters?.name
    ? articlesForUser?.articleList?.filter((article) => {
      let searchTerm = filters?.name?.toLowerCase()
      if (!searchTerm) return true

      const fieldsToSearch = [
        article?.author,
        article?.headline,
        article?.reference_details,
        article?.significance_details,
        article?.storyIdea,
        article?.reviewer_comment,
      ]
      return fieldsToSearch.some((field) =>
        field?.toLowerCase()?.includes(searchTerm),
      )
    })
    : articlesForUser?.articleList?.slice()
  const articlesGroupedByStatus = _.groupBy(
    filteredArticleList,
    (x) => x?.status,
  )

  useEffect(() => {
    rangePickerRef.current = filters
  }, [filters])

  useEffect(() => {
    const storedStoryType = JSON.parse(localStorage.getItem('selectStoryType'));
    if (storedStoryType) {
      setSelectedStory(storedStoryType);
    }
  }, []);

  useEffect(() => {
    if (selectStoryType) {
      localStorage.setItem('selectStoryType', JSON.stringify(selectStoryType));
    }
  }, [selectStoryType]);
  const tabList = [
    {
      key: 'All',
      label: `All (${filteredArticleList?.length || 0})`,
    },
    {
      key: 'Draft',
      label: `Draft (${articlesGroupedByStatus?.Draft?.length
        ? articlesGroupedByStatus?.Draft?.length
        : 0
        })`,
    },
    {
      key: 'Idea Approved',
      label: `Idea Approved (${(articlesGroupedByStatus?.['WIP']?.length
        ? articlesGroupedByStatus?.['WIP']?.length
        : 0) +
        (articlesGroupedByStatus?.['Published']?.length
          ? articlesGroupedByStatus?.['Published']?.length
          : 0) +
        (articlesGroupedByStatus?.['Rework']?.length
          ? articlesGroupedByStatus?.['Rework']?.length
          : 0) +
        (articlesGroupedByStatus?.['Pending Edit']?.length
          ? articlesGroupedByStatus?.['Pending Edit']?.length
          : 0)
        })`,
    },
    {
      key: 'Story On Hold',
      label: `Story On Hold (${articlesGroupedByStatus?.['Story On Hold']?.length
        ? articlesGroupedByStatus?.['Story On Hold']?.length
        : 0
        })`,
    },
    {
      key: 'WIP',
      label: `WIP (${articlesGroupedByStatus?.['WIP']?.length
        ? articlesGroupedByStatus?.['WIP']?.length
        : 0
        })`,
    },
    {
      key: 'Published',
      label: `Published (${articlesGroupedByStatus?.['Published']?.length
        ? articlesGroupedByStatus?.['Published']?.length
        : 0
        })`,
    },
    {
      key: 'Pending Edit',
      label: `Pending Edit (${articlesGroupedByStatus?.['Pending Edit']?.length
        ? articlesGroupedByStatus?.['Pending Edit']?.length
        : 0
        })`,
    },
    {
      key: 'Rework',
      label: `Rework (${articlesGroupedByStatus?.['Rework']?.length
        ? articlesGroupedByStatus?.['Rework']?.length
        : 0
        })`,
    },
    {
      key: 'Rejected',
      label: `Rejected (${articlesGroupedByStatus?.['Rejected']?.length
        ? articlesGroupedByStatus?.['Rejected']?.length
        : 0
        })`,
    },
    {
      key: 'Edited',
      label: `Edited (${articlesGroupedByStatus?.['Edited']?.length
        ? articlesGroupedByStatus?.['Edited']?.length
        : 0
        })`,
    },
  ]
  const downloadFile = async (url) => {
    try {
      const response = await fetch(url, { cache: 'no-store', mode: 'cors' });
      if (response.status === 304) {
        alert("The file has not changed; using the cached version.");
      } else if (!response.ok) {
        throw new Error(`Failed to fetch file: ${response.statusText}`);
      }

      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      const fileName = decodeURIComponent(url.split('/').pop());
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error('Error downloading the file:', error);
      alert('Failed to download the file.');
    }
  };




  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 150,
    },
    {
      title: 'Headline',
      dataIndex: 'headline',
      key: 'headline',
      width: 250,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      width: 150,
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: 150,
    },
    {
      title: 'Infographics',
      key: 'Infographics',
      render: (_, data) => {
        const shouldShowIcons =
          (data?.bannerUrl || data?.articleUrl || data?.infoGraphicsUrl);
        return shouldShowIcons ? (
          <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <div
              style={{
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '30px',
                height: '30px',
              }}
            >
              {data.bannerUrl ? (
                <span
                  style={{
                    display: 'inline-flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '24px',
                    height: '24px',
                    borderRadius: '50%',
                    backgroundColor: 'rgb(251, 229, 9)',
                    color: 'black',
                    fontWeight: 'bold',
                    fontSize: '14px',
                    cursor: 'pointer',
                    textAlign: 'center',
                  }}
                  onClick={() => downloadFile(data.bannerUrl)}
                  title="Download Banner"
                >
                  B
                </span>
              ) : null}
            </div>
            <div
              style={{
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '30px',
                height: '30px',
              }}
            >
              {data.articleUrl ? (
                <span
                  style={{
                    display: 'inline-flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '24px',
                    height: '24px',
                    borderRadius: '50%',
                    backgroundColor: 'rgb(251, 229, 9)',
                    color: 'black',
                    fontWeight: 'bold',
                    fontSize: '14px',
                    cursor: 'pointer',
                    textAlign: 'center',
                  }}
                  onClick={() => downloadFile(data.articleUrl)}
                  title="Download Article"
                >
                  A
                </span>
              ) : null}
            </div>
            <div
              style={{
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '30px',
                height: '30px',
              }}
            >
              {data.infoGraphicsUrl ? (
                <span
                  style={{
                    display: 'inline-flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '24px',
                    height: '24px',
                    borderRadius: '50%',
                    backgroundColor: 'rgb(251, 229, 9)',
                    color: 'black',
                    fontWeight: 'bold',
                    fontSize: '14px',
                    cursor: 'pointer',
                    textAlign: 'center',
                  }}
                  onClick={() => downloadFile(data.infoGraphicsUrl)}
                  title="Download Infographics"
                >
                  I
                </span>
              ) : null}
            </div>
          </div>
        ) : (
          <h4 style={{ fontFamily: 'Arial, sans-serif', fontWeight: 'normal', marginLeft: '7px' }}>
            {data?.infographics}
          </h4>

        );
      },
    },


    {
      title: 'Updated At',
      dataIndex: 'updated_at',
      key: 'updated_at',
      width: 200,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      render: (data) => {
        return (
          <Select
            className="no-border-select "
            placeholder="Select status"
            value={data?.status}
            onChange={(value) => {
              if (value === "Published" && !userDetails?.roles?.includes("admin")) {
                handleShowPublishedArticleLink(value, data?._id);
              } else if (
                (value === "WIP" || value === "Rejected" || value === "Rework") &&
                userDetails?.roles?.includes("admin")
              ) {
                handleShowReviewersCommentModal(value, data);
              } else if (
                (value === "Pending Edit" || value === "Edited") &&
                (userDetails?.roles?.includes("admin") || userDetails?.roles?.includes("copy-editor"))
              ) {
                handleChangeStatus(value, data?._id);
              } else {
                handleChangeStatus(value, data?._id);
              }
            }}
            style={{ width: 150, border: "none", boxShadow: "none" }}
          >
            {userDetails?.roles?.includes('admin') || userDetails?.roles?.includes('copy-editor')
              ? StatusOptionsForAdmin?.map((option, index) => (
                <Option key={option.value} value={option.value}>
                  <Tag color={option.color}>{option.label}</Tag>
                </Option>
              ))
              : StatusOptions?.map((option) => (
                <Option key={option.value} value={option.value}>
                  <Tag color={option.color}>{option.label}</Tag>
                </Option>
              ))}
          </Select>
        )
      },
    },
  ]

  columns.push({
    title: '',
    dataIndex: 'articleContent',
    key: 'articleContent',
    width: 100,
    render: (articleData) => {
      let fullName = '';
      if (articleData?.createdBy && typeof articleData.createdBy === 'string') {
        const namePart = articleData.createdBy.split('@')?.[0];
        fullName = namePart
          ? namePart
            .split('.')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
          : 'John Doe';
      } else {
        fullName = 'John Doe';
      }
      return (
        articleData?.status === 'Published' && !userDetails?.roles?.includes('admin') ? null : (
          <div style={{ display: 'flex' }}>
            <Tooltip title="Article Content">
              <Button
                onClick={() => createFileForArticle(articleData._id, fullName, articleData?.headline)}
                style={{ backgroundColor: 'rgb(251, 229, 9)', color: 'black' }}
                type="primary"
                shape="circle"
                icon={<HiOutlineDocumentText style={{ fontSize: '19px' }} />}
              />
            </Tooltip>
          </div>
        )
      );
    },
  });

  if (!isCreative && !isCopyEditor) {
    columns.push({
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: 100,
      paddingLeft: 50,
      render: (articleData) => {
        return articleData?.status === 'Published' ? null : (
          <Tooltip title="Edit Article">
            <Button
              onClick={() => {
                handleEditArticle(articleData)
              }}
              style={{ backgroundColor: 'rgb(251, 229, 9', color: 'black', left: '-25px' }}
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
            />
          </Tooltip>
        )
      },
    })
  }
  if (userDetails?.roles?.includes('admin')) {
    columns.push({
      title: '',
      dataIndex: 'delete',
      key: 'delete',
      width: 50,
      render: (articleData) => {
        return articleData?.status === 'Published' &&
          !userDetails?.roles?.includes('admin') ? null : (
          <Tooltip title='Delete Article' overlayStyle={{ backgroundColor: 'black', borderRadius: '8px' }} >
            <Button
              onClick={() => {
                handleShowDeleteConfirmation(articleData)
              }}
              style={{ backgroundColor: 'rgb(251, 229, 9', color: 'black', left: '-50px' }}
              type="primary"
              shape="circle"
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        )
      },
    })
  }
  columns.push({
    title: (
      <div
        onClick={() => setFilterByBookmark((prev) => !prev)}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",

        }}
      >
        {filterByBookmark ? (
          <MdBookmarkAdded style={{ color: "black", fontSize: "20px", marginRight: "60px" }} />
        ) : (
          <CiBookmarkPlus style={{ color: "black", fontSize: "20px", marginRight: "60px" }} />
        )}
      </div>
    ),
    dataIndex: 'bookMarked',
    key: 'bookMarked',
    width: 100,
    render: (articleData) => {
      const isBookmarked = bookmarkedStates[articleData._id] ?? articleData.bookmarked;
      return (
        <div style={{ display: 'flex' }}>
          <div onClick={() => handleBookmarkClick(articleData)}>
            {isBookmarked ? (
              <FaBookmark style={{ cursor: 'pointer', color: 'black', marginLeft: '3px' }} />
            ) : (
              <FaRegBookmark style={{ cursor: 'pointer', color: 'black', marginLeft: '3px' }} />
            )}
          </div>
        </div>
      );
    },
  });

  //delete article
  useEffect(() => {
    function start() {
      gapi.client
        .init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          scope: SCOPES,
          discoveryDocs: [
            'https://docs.googleapis.com/$discovery/rest?version=v1',
          ],
        })
        .then(() => {
          console.log('GAPI initialized');
        })
        .catch((error) => {
          console.error('Error initializing GAPI:', error);
        });
    }
    gapi.load('client:auth2', start);
  }, [API_KEY, CLIENT_ID, SCOPES]);

  const createFileForArticle = async (articleId, fullName, headline) => {
    const authInstance = gapi.auth2.getAuthInstance();
    if (!authInstance.isSignedIn.get()) {
      await authInstance.signIn();
    }
    try {
      const response = await fetch(`${apiUrl}/v1/editorial/storyIdea/${articleId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${userDetails?.token}`,
        },
      });
      const articleData = await response.json();
      if (articleData?.data?.articleContent) {
        const docUrl = `https://docs.google.com/document/d/${articleData.data.articleContent}/edit`;
        window.open(docUrl, '_blank');
      } else {
        const accessToken = authInstance.currentUser.get().getAuthResponse().access_token;
        const docResponse = await fetch('https://docs.googleapis.com/v1/documents', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ title: `${fullName} | ${headline}` }),
        });
        const docData = await docResponse.json();
          message.success("Document created successfully! Opening now...");
          const docUrl = `https://docs.google.com/document/d/${docData.documentId}/edit`;
          setTimeout(() => window.open(docUrl, "_blank"), 2000);
        if (docData?.documentId) {
          const payload = { articleContent: docData.documentId };
          const saveResponse = await fetch(`${apiUrl}/v1/editorial/storyIdea/${articleId}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${userDetails?.token}`,
            },
            body: JSON.stringify(payload),
          });
          if (saveResponse.ok) {
            try {
              if (!gapi.client.drive) {
                await gapi.client.load("drive", "v3");
              }

              // Set permissions
              await gapi.client.drive.permissions.create({
                fileId: docData.documentId,
                resource: {
                  role: "reader",
                  type: "anyone",
                },
              });

           
            } catch (error) {
              console.error("Error setting permissions:", error);
              message.error("Failed to set permissions for the document.");
            }
          } else {
            const result = await saveResponse.json();
            message.error(result.message || 'Failed to save documentId.');
          }
        } else {
          console.error('Error creating document:', docData);
          message.error('Failed to create document.');
        }
      }
    } catch (error) {
      console.error('Error processing document:', error);
      message.error('An error occurred while processing the document.');
    }
  };


  const handleBookmarkClick = async (articleData) => {
    try {
      const updatedBookmarkStatus = !bookmarkedStates[articleData._id];
      setBookmarkedStates((prev) => ({
        ...prev,
        [articleData._id]: updatedBookmarkStatus,
      }));
      const payload = { bookmarked: updatedBookmarkStatus };
      const response = await fetch(
        `${apiUrl}/v1/editorial/storyIdea/${articleData._id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userDetails?.token}`,
          },
          body: JSON.stringify(payload),
        }
      );

      const result = await response.json();

      if (response.ok) {
      } else {
        setBookmarkedStates((prev) => ({
          ...prev,
          [articleData._id]: !updatedBookmarkStatus,
        }));
        messageHandler(result.message || 'Failed to update bookmark.', 'error');
      }
    } catch (error) {
      console.error('Error updating bookmark:', error);
      setBookmarkedStates((prev) => ({
        ...prev,
        [articleData._id]: !bookmarkedStates[articleData._id],
      }));
      messageHandler('Something went wrong while updating bookmark!', 'error');
    }
  };
  const handleShowDeleteConfirmation = (articleData) => {
    setShowDeleteConfimation(true)
    setIsEdit(articleData?._id)
  }
  const handleHideDeleteConfirmation = () => {
    setShowDeleteConfimation(false)
    setIsEdit(false)
  }

  //published article link
  const handleShowPublishedArticleLink = (value, id) => {
    setShowPublishedArticleLink(true)
    setIsEdit(id)
  }
  const handleHidePublishedArticleLink = () => {
    setShowPublishedArticleLink(false)
    setArticleUrl('')
    setIsEdit('')
  }

  //Reviewers Comments
  const handleShowReviewersCommentModal = (value, item) => {
    setShowReviewersComment(true)
    setIsEdit({
      item,
      value,
    })
  }
  const handleHideReviewersCommentModal = () => {
    setShowReviewersComment(false)
    setReviewersComment('')
    setIsEdit('')
  }

  const onDeleteConfirm = async () => {
    setIsLoadingDeleteArticle(true)
    try {
      const response = await fetch(
        `${apiUrl}/v1/editorial/storyIdea/${isEdit}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userDetails?.token}`,
          },
        },
      )
      const result = await response.json()
      if (result?.data?._id) {
        handleHideDeleteConfirmation()
        if (filters?.dateRange?.[0] && filters?.dateRange?.[1]) {
          getAllArticlesForTheUser(
            dayjs(filters?.dateRange?.[0], 'YYYY-MM-DD').format('YYYY-MM-DD'),
            dayjs(filters?.dateRange?.[1], 'YYYY-MM-DD').format('YYYY-MM-DD'),
            filters?.sort,
          )
        } else {
          getAllArticlesForTheUser(today, tomorrow, filters?.sort)
        }
      }
      setIsLoadingDeleteArticle(false)
      setSelectedCategory('Long-form')
    } catch (error) {
      setIsLoadingDeleteArticle(false)
      messageHandler('Something went wrongs!!!', 'error')
      return error
    }
  }

  const handleChangeStatus = async (status, id) => {
    const response = await fetch(`${apiUrl}/v1/editorial/storyIdea/${id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${userDetails?.token}`,
      },
    });
    const articleData = await response.json();
    console.log(articleData.data.createdBy)
    try {
      const payload = {
        status: status,
      };
    
        payload.updatedAt = moment().format('YYYY-MM-DD HH:mm:ss');

      if (status === 'Edited') {
        payload.editedBy = userDetails?.email;
        payload.editedAt = moment().format('YYYY-MM-DD HH:mm:ss');
      }

      const response = await fetch(`${apiUrl}/v1/editorial/storyIdea/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userDetails?.token}`,
        },
        body: JSON.stringify(payload),
      })
      const result = await response.json()

      if (result?.data?._id) {
        messageHandler('Status changed Successfully', 'success')
        setSelectedCategory('Long-form')
        if (filters?.dateRange?.[0] && filters?.dateRange?.[1]) {
          getAllArticlesForTheUser(
            dayjs(filters?.dateRange?.[0], 'YYYY-MM-DD').format('YYYY-MM-DD'),
            dayjs(filters?.dateRange?.[1], 'YYYY-MM-DD').format('YYYY-MM-DD'),
            filters?.sort,
          )
        } else {
          getAllArticlesForTheUser(today, tomorrow, filters?.sort)
        }
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const handlePublish = async () => {
    if (
      articleUrl &&
      !articleUrl.includes('analyticsindiamag.com') && !articleUrl.includes('youtube.com')
    ) {
      messageHandler('Enter a valid article URL', 'error')
      return
    }
    try {
      const response = await fetch(
        `${apiUrl}/v1/editorial/storyIdea/${isEdit}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userDetails?.token}`,
          },
          body: JSON.stringify({
            status: 'Published',
            article_link: articleUrl ? articleUrl : '',
            updatedAt:moment().format('YYYY-MM-DD HH:mm:ss'),
          }),
        },
      )
      const result = await response.json()
      if (result?.data?._id) {
        setSelectedCategory('Long-form')
        handleHidePublishedArticleLink()
        messageHandler('Status changed Successfully', 'success')
        if (filters?.dateRange?.[0] && filters?.dateRange?.[1]) {
          getAllArticlesForTheUser(
            dayjs(filters?.dateRange?.[0], 'YYYY-MM-DD').format('YYYY-MM-DD'),
            dayjs(filters?.dateRange?.[1], 'YYYY-MM-DD').format('YYYY-MM-DD'),
            filters?.sort,
          )
        } else {
          getAllArticlesForTheUser(today, tomorrow, filters?.sort)
        }
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const handleApproveOrReject = async () => {
    setIsLoadingDeleteArticle(true)
    try {
      const response = await fetch(
        `${apiUrl}/v1/editorial/storyIdea/${isEdit?.item?._id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userDetails?.token}`,
          },
          body: JSON.stringify({
            status: isEdit?.value,
            reviewer_comment: reviewersComment ? reviewersComment : '',
          }),
        },
      )
      const result = await response.json()
      if (result?.data?._id) {
        setIsLoadingDeleteArticle(false)
        handleHideReviewersCommentModal()
        setSelectedCategory('Long-form')
        messageHandler('Status changed Successfully', 'success')
        if (filters?.dateRange?.[0] && filters?.dateRange?.[1]) {
          getAllArticlesForTheUser(
            dayjs(filters?.dateRange?.[0], 'YYYY-MM-DD').format('YYYY-MM-DD'),
            dayjs(filters?.dateRange?.[1], 'YYYY-MM-DD').format('YYYY-MM-DD'),
            filters?.sort,
          )
        } else {
          getAllArticlesForTheUser(today, tomorrow, filters?.sort)
        }
      }
    } catch (err) {
      setIsLoadingDeleteArticle(false)
      console.log(err, 'err')
    }
  }

  const handleEditArticle = (articleData) => {
    form.setFieldsValue({
      storyIdea: articleData?.storyIdea,
      headline: articleData?.headline,
      category: articleData?.category,
      significance_details: articleData?.significance_details,
      reference_details: articleData?.reference_details,
      date: dayjs(articleData?.date, 'YYYY-MM-DD'),
      infographicsIdea: articleData?.infographicsIdea
        ? articleData?.infographicsIdea
        : '',
      comments: articleData?.comments ? articleData?.comments : '',
      status: articleData?.status,
      infographics: articleData?.infographics ? 'yes' : 'no',
    })
    setSelectedCategory(articleData?.category)
    setShowInfoGraphics(articleData?.infographics)
    setIsModalOpen(true)
    setIsEdit(articleData?._id)
  }

  const messageHandler = useCallback(
    (title, status) => {
      messageApi.open({
        type: status,
        content: title,
        duration: 5,
      })
    },
    [messageApi],
  )

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    form.submit()
  }
  const handleCancel = () => {
    setIsModalOpen(false)
    setIsEdit(false)
    handleClearForm()
    setIsEverythingUnique(true)
    setSelectedCategory('Long-form')
  }

  let groupedAuthors = _.groupBy(articlesForUser?.articleList, x => x?.author);
  let arrayOfAuthors = Object.keys(groupedAuthors)?.map((email) => {
    return {
      name: email.split('@')?.[0].split('.')?.[0] !== "undefined" ? email.split('@')?.[0].split('.')?.[0].charAt(0).toUpperCase() + email.split('@')?.[0].split('.')?.[0].slice(1) : "John",
      email: email
    }
  }
  )


  const getArticlesList = () => {
    let finalList = articlesForUser?.articleList?.slice()
    if (filterByBookmark) {
      finalList = finalList.filter((article) => bookmarkedStates[article._id] ?? article.bookmarked);
    }
    if (activeTab !== 'All' && activeTab === 'Idea Approved') {
      finalList = finalList?.filter(
        (x) =>
          x?.status === 'WIP' ||
          x?.status === 'Published' ||
          x?.status === 'Pending Edit' ||
          x?.status === 'Rework',
      )
    } else if (activeTab !== 'All') {
      finalList = finalList?.filter((x) => x?.status === activeTab)
    }

    //handle filters
    if (filters?.name) {
      finalList = finalList?.filter((article) => {
        let searchTerm = filters?.name?.toLowerCase()

        if (!searchTerm) return true

        const fieldsToSearch = [
          article?.author,
          article?.headline,
          article?.reference_details,
          article?.significance_details,
          article?.storyIdea,
          article?.reviewer_comment,
        ]
        return fieldsToSearch.some((field) =>
          field?.toLowerCase()?.includes(searchTerm),
        )
      })
    }

    if (selectStoryType) {
      if (selectStoryType?.includes('Select All')) {
        return finalList;
      } else if (selectStoryType?.length > 0) {
        finalList = finalList.filter(
          (article) => selectStoryType?.includes(article.category),
        )
      }
    }
    if (selectedAuthor) {
      if (selectedAuthor?.length > 0) {
        finalList = finalList?.filter((article) =>
          selectedAuthor?.includes(article.author)

        );
      }
    }


    return finalList;
  }
  const edited = _.groupBy(
    getArticlesList().filter((x) => x?.editedBy !== undefined || x?.category === 'Edited Article'),
    (x) => x.editedBy ? x.editedBy : x.createdBy
  );
  const EditedSummary = Object.keys(edited)?.map((editedBy, index) => {
    let fullName = editedBy.split('@')?.[0]?.split('.');
    let finalName = !fullName?.[1]
      ? changeCase.capitalCase(fullName?.[0])
      : changeCase.capitalCase(fullName?.[0]) +
      ' ' +
      changeCase.capitalCase(fullName?.[1])
    let groupedByedited = _.groupBy(
      edited?.[editedBy],
      (x) => x?.status,
    )
    return {
      key: index,
      name: finalName,
      Others: groupedByedited?.['Edited']?.length
        ? groupedByedited?.['Edited']?.length
        : 0,
      Published: groupedByedited?.['Published']?.length
        ? groupedByedited?.['Published']?.length
        : 0,
      Total: edited?.[editedBy]?.length,
    }
  })
  const articlesByName = _.groupBy(getArticlesList(), (x) => x?.author);
  const summaryData = Object.keys(articlesByName)?.map((author, index) => {
    let fullName = author
      ? author?.split('@')?.[0]?.split('.')
      : ['John', 'Doe']
    let finalName = !fullName?.[1]
      ? changeCase.capitalCase(fullName?.[0])
      : changeCase.capitalCase(fullName?.[0]) +
      ' ' +
      changeCase.capitalCase(fullName?.[1])
    let groupedByStatuses = _.groupBy(
      articlesByName?.[author],
      (x) => x?.status,
    )
    return {
      key: index,
      name: finalName && finalName !== 'Undefined' ? finalName : 'John Doe',
      Draft: groupedByStatuses?.['Draft']?.length
        ? groupedByStatuses?.['Draft']?.length
        : 0,
      'Idea Approved':
        (groupedByStatuses?.['WIP']?.length
          ? groupedByStatuses?.['WIP']?.length
          : 0) +
        (groupedByStatuses?.['Published']?.length
          ? groupedByStatuses?.['Published']?.length
          : 0) +
        (groupedByStatuses?.['Rework']?.length
          ? groupedByStatuses?.['Rework']?.length
          : 0) +
        (groupedByStatuses?.['Pending Edit']?.length
          ? groupedByStatuses?.['Pending Edit']?.length
          : 0),
      'Story On Hold': groupedByStatuses?.['Story On Hold']?.length
        ? groupedByStatuses?.['Story On Hold']?.length
        : 0,
      WIP: groupedByStatuses?.['WIP']?.length
        ? groupedByStatuses?.['WIP']?.length
        : 0,
      Published: groupedByStatuses?.['Published']?.length
        ? groupedByStatuses?.['Published']?.length
        : 0,
      Rejected: groupedByStatuses?.['Rejected']?.length
        ? groupedByStatuses?.['Rejected']?.length
        : 0,
      Total: articlesByName?.[author]?.length,
    }
  })
  const publishSummaryData = Object.keys(articlesByName)?.map((author,index) => {
    let fullName = author
      ? author?.split('@')?.[0]?.split('.')
      : ['John', 'Doe'];
    let finalName = !fullName?.[1]
      ? changeCase.capitalCase(fullName?.[0])
      : changeCase.capitalCase(fullName?.[0]) +
      ' ' +
      changeCase.capitalCase(fullName?.[1]);
    const publishedArticles = articlesByName?.[author]?.filter(
      (article) => article?.status === 'Published'
    );
    const groupedByCategory = _.groupBy(publishedArticles, (x) => x?.category);
    return {
      key: index,
      name: finalName && finalName !== 'Undefined' ? finalName : 'John Doe',
      News: groupedByCategory?.['News']?.length || 0,
      'Long-form': groupedByCategory?.['Long-form']?.length || 0,
      'Daily Narrative': groupedByCategory?.['Daily Narrative']?.length || 0,
      Newsletter: groupedByCategory?.['Newsletter']?.length || 0,
      'Event': groupedByCategory?.['Event']?.length || 0,
      'Brand content': groupedByCategory?.['Brand content']?.length || 0,
      Video: groupedByCategory?.['Video']?.length || 0,
    };
  });

  useCopilotReadable({
    description: "Editorial articles list",
    value: summaryData,
  });
  //pranay
  const dataNew = getArticlesList()?.map((item, index) => {
    let fullName = item?.author
      ? item?.author?.split('@')?.[0]?.split('.')
      : ['John', 'Doe']
    let finalName = !fullName?.[1]
      ? changeCase.capitalCase(fullName?.[0])
      : changeCase.capitalCase(fullName?.[0]) +
      ' ' +
      changeCase.capitalCase(fullName?.[1])
    return {
      key: index,
      name: finalName,
      headline: item?.headline,
      category: item?.category,
      significance_details: item?.significance_details,
      reference_details: item?.reference_details,
      date: moment(item?.date).format('DD-MM-YYYY'),
      infographics: item?.infographics ? 'Yes' : 'No',
      status: item,
      infographicsIdea: item?.infographicsIdea,
      updated_at: moment(item?.updatedAt).format('DD-MM-YYYY hh:mm A'),
      comments: item?.comments,
      storyIdea: item?.storyIdea,
      edit: item,
      delete: item,
      bookMarked: item,
      articleContent: item,
      reviewer_comment: item?.reviewer_comment,
      bannerUrl: item?.bannerUrl,
      articleUrl: item?.articleUrl,
      infoGraphicsUrl: item?.infoGraphicsUrl,

    }
  })

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Makes the scroll smooth
    })
  }

  //form states
  const onFinish = async (values) => {
    let finalPayload = Object.assign({}, values, {
      date: moment(new Date(values?.date))?.format('YYYY-MM-DD'),
      infographics: values?.infographics === 'yes' ? true : false,
    })
    console.log("payload of new article", finalPayload)

    const hasUniqueValues =
      new Set(Object.values(finalPayload)).size ===
      Object.keys(finalPayload).length
    if (!hasUniqueValues) {
      setIsEverythingUnique(false)
      return
    } else {
      setIsEverythingUnique(true)
    }

    if (selectedCategory === 'Event') {
      finalPayload['status'] = 'Edited'
    } else {
      finalPayload['status'] = 'Draft'
    }

    try {
      setIsLoadingAddArticle(true)
      if (isEdit) {
      const responsearticle = await fetch(`${apiUrl}/v1/editorial/storyIdea/${isEdit}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userDetails?.token}`,
          },
        },
      )
        const responseforaricleget=await responsearticle.json();
        console.log("response of individual", responseforaricleget.data.createdBy)
      
        finalPayload['author'] = responseforaricleget?.data?.createdBy;
        const response = await fetch(
          `${apiUrl}/v1/editorial/storyIdea/${isEdit}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${userDetails?.token}`,
            },
            body: JSON.stringify(finalPayload),
          },
        )
        const result = await response.json()
        console.log("Result name",result)
        if (result?.data?._id) {
          setIsLoadingAddArticle(false)
          setIsModalOpen(false)
          handleClearForm()
          setSelectedCategory('Long-form')
          setIsEdit(true)
          if (filters?.dateRange?.[0] && filters?.dateRange?.[1]) {
            getAllArticlesForTheUser(
              dayjs(filters?.dateRange?.[0], 'YYYY-MM-DD').format('YYYY-MM-DD'),
              dayjs(filters?.dateRange?.[1], 'YYYY-MM-DD').format('YYYY-MM-DD'),
              filters?.sort,
            )
          } else {
            getAllArticlesForTheUser(today, tomorrow, filters?.sort)
          }
          messageHandler('Article is updated!!', 'success')
        }
      } else {
        finalPayload['author'] = userDetails?.email;
        const response = await fetch(`${apiUrl}/v1/editorial/storyIdea`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userDetails?.token}`,
          },
          body: JSON.stringify(finalPayload),
        })
        const result = await response.json()
console.log("Post",result)
        if (result?.data?._id) {
          setIsLoadingAddArticle(false)
          setIsModalOpen(false)
          setFilters(
            Object.assign({}, filters, {
              dateRange: [],
            }),
          )
          handleClearForm()
          setIsEdit(true)
          setSelectedCategory('Long-form')
          messageHandler('Article is added!!', 'success')
          if (filters?.dateRange?.[0] && filters?.dateRange?.[1]) {
            getAllArticlesForTheUser(
              dayjs(filters?.dateRange?.[0], 'YYYY-MM-DD').format('YYYY-MM-DD'),
              dayjs(filters?.dateRange?.[1], 'YYYY-MM-DD').format('YYYY-MM-DD'),
              filters?.sort,
            )
          } else {
            getAllArticlesForTheUser(today, tomorrow, filters?.sort)
          }
        }
      }
      setIsLoadingAddArticle(false)
    } catch (error) {
      setIsLoadingAddArticle(false)
      messageHandler('Something went wrong!!!', 'error')
      return error
    }
  }

  const getAllArticlesForTheUser = useCallback(
    async (startDate, endDate, type) => {
      setArticlesForUser({
        isLoading: true,
        articleList: [],
      })

      try {
        const response = await fetch(
          `${apiUrl}/v1/editorial/allStoryIdeas?${type === 'created' ? 'startDate' : 'startUpdatedDate'}=${startDate}&${type === 'created' ? 'endDate' : 'endUpdatedDate'
          }=${endDate}&hour=${hour}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${userDetails?.token}`,
            },
          },
        )
        const result = await response.json()
        if (result?.data?.length > 0) {
          toast.success("Auto Refreshing ", {
            position: "top-right",
            autoClose: 2000,
          });
          let sortedData = [...result.data];
          if (type === 'updated') {
            sortedData = sortedData.sort(
              (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
            );
          }
          setArticlesForUser({
            isLoading: false,
            articleList: sortedData,
          })
        } else {
          setArticlesForUser((prev) => ({
            ...prev,
            isLoading: false,
          }))
        }
      } catch (error) {
        setArticlesForUser({
          isLoading: false,
          articleList: [],
        })
        messageHandler('Something went wrong in fetching articles!!!', 'error')
        return error
      }
    },
    [userDetails?.token, messageHandler, apiUrl, hour],
  )

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (
        rangePickerRef.current?.dateRange?.[0] &&
        rangePickerRef.current?.dateRange?.[1]
      ) {
        getAllArticlesForTheUser(
          dayjs(rangePickerRef.current?.dateRange?.[0], 'YYYY-MM-DD').format(
            'YYYY-MM-DD',
          ),
          dayjs(rangePickerRef.current?.dateRange?.[1], 'YYYY-MM-DD').format(
            'YYYY-MM-DD',
          ),
          filters?.sort,
        )

      } else {
        getAllArticlesForTheUser(today, tomorrow, filters?.sort)
      }

    }, 60 * 10 * 1000)

    return () => clearInterval(intervalId)
  }, [filters?.sort, today, tomorrow, getAllArticlesForTheUser])

  useEffect(() => {

    let tomorrowsDate = moment().format('YYYY-MM-DD');
    let todaysDate = moment().subtract(2, 'months').format('YYYY-MM-DD');
    getAllArticlesForTheUser(todaysDate, tomorrowsDate, 'created')
  }, [getAllArticlesForTheUser])

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  const handleClearForm = () => {
    form.setFieldsValue({
      storyIdea: '',
      headline: '',
      category: '',
      significance_details: '',
      reference_details: '',
      date: '',
      infographicsIdea: '',
      comments: '',
      status: '',
      infographics: '',
    })
    setShowInfoGraphics(false)
  }

  const handleChangeFilters = (type, value) => {
    setFilters(
      Object.assign({}, filters, {
        [type]: value,
      }),
    )
  }

  const handleExportCSV = () => {
    const headers = [
      'Name',
      'Headline',
      'Category',
      'Significance Details',
      'Reference Details',
      'Date',
      'Infographics',
      'Status',
      'Infographics Idea',
      'Updated At',
      'Comments',
      'Story Idea',
      'Reviewer Comment',
    ]
    const csvData = dataNew?.map((item) => [
      item.name ? item.name : '',
      item.headline ? item.headline : '',
      item.category ? item.category : '',
      item.significance_details ? item.significance_details : '',
      item.reference_details ? item.reference_details : '',
      item.date ? item.date : '',
      item.infographics ? item.infographics : ' ',
      item.status?.status || '', // Add appropriate property for 'status'
      item.infographicsIdea ? item.infographicsIdea : ' ',
      item.updated_at ? item.updated_at : '',
      item.comments ? item.comments : '',
      item.storyIdea ? item.storyIdea : '',
      item.reviewer_comment ? item.reviewer_comment : '',
    ])
    const csvContent = [headers, ...csvData]
      .map((row) => row.map((value) => `"${value || ''}"`).join(','))
      .join('\n')

    saveAs(
      new Blob([csvContent], { type: 'text/csv;charset=utf-8;' }),
      'articles.csv',
    )
  }

  const validateWordCount = (_, value) => {
    const words = value ? value.trim().split(/\s+/).length : 0
    if (words < 30) {
      return Promise.reject(new Error('The idea must have at least 30 words!'))
    } else if (words > 50) {
      return Promise.reject(
        new Error('The idea must have no more than 50 words!'),
      )
    } else {
      return Promise.resolve()
    }
  }





  return (
    <div style={{ maxWidth: '1440px', margin: '0 auto' }}>
      {contextHolder}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '15px',
        }}
      >
      <CopilotPopup
        instructions={"You are assisting the user as best as you can. Answer in the best way possible given the data you have."}
        labels={{
          title: "💡Idea Sigamani🫠",
          initial: "Need any help? 💭",
        }}
      />
        <Title level={4}>
          Welcome{' '}
          {userDetails?.email?.split('@')?.[0]?.split('.')?.[0]
            ? changeCase.capitalCase(
              userDetails?.email?.split('@')?.[0]?.split('.')?.[0],
            )
            : ''}
        </Title>
        {!isCreative && (
          <Button
            onClick={() => {
              showModal()
              setIsEdit(false)
              handleClearForm()
              form.setFieldsValue({ date: dayjs(moment()) })
            }}
            type="primary"
            icon={<FileAddOutlined />}
            style={{ backgroundColor: '#000' }}
          >
            Add New
          </Button>
        )}
      </div>
      <Card>
        <Tabs
          defaultActiveKey="1"
          items={tabList}
          onChange={(val) => {
            setActiveTab(val)
          }}
        />

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '25px',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Select

            style={{ width: 150, marginRight: '15px', cursor: 'pointer' }}
            placeholder="Select an author"
            onChange={(value) => {
              setSelectedAuthor(value)
              setSelectedAuthor(value)
            }}
            value={selectedAuthor}
            allowClear
          >
            {arrayOfAuthors?.map((author) => (
              <Option key={author?.email} value={author?.email}>
                {author?.name}
              </Option>
            ))}
          </Select>




          <Select
            mode="multiple"
            style={{ width: 250, marginRight: '15px', cursor: 'pointer' }}
            placeholder="Select a story category"
            value={selectStoryType}
            onChange={(value) => {
              setSelectedStory(value)
            }}

            allowClear
          >
            <Option value="Long-form">Long-form</Option>
            <Option value="News">News</Option>
            <Option value="Daily Narrative">Daily Narrative</Option>
            <Option value="Newsletter">Newsletter</Option>
            <Option value="Brand content">Brand content</Option>
            <Option value="Event">Event</Option>
            <Option value="Video">Video</Option>
          </Select>

          <Input
            style={{ width: 250, marginRight: '15px' }}
            onChange={(e) => {
              handleChangeFilters('name', e?.target?.value)
            }}
            size="large"
            placeholder="Search"
            prefix={<SearchOutlined />}
          />
          <Select
            style={{ width: 250, marginRight: '15px' }}
            placeholder="Select Filter"
            value={filters?.sort}
            onChange={(value) => {
              setFilters({
                ...filters,
                sort: value,
              });

              const startDate = filters?.dateRange?.[0]
                ? dayjs(filters?.dateRange?.[0], 'YYYY-MM-DD').format('YYYY-MM-DD')
                : today;

              const endDate = filters?.dateRange?.[1]
                ? dayjs(filters?.dateRange?.[1], 'YYYY-MM-DD').format('YYYY-MM-DD')
                : tomorrow;

              getAllArticlesForTheUser(startDate, endDate, value);
            }}
          >
            <Option value="created">Article Date</Option>
            <Option value="updated">Last Updated (Published)</Option>
          </Select>         
          <RangePicker
            style={{ width: 250 }}
            onChange={(date, dateString) => {
              if (dateString?.[0] && dateString?.[1]) {
                setFilters(
                  Object.assign({}, filters, {
                    dateRange: date,
                  }),
                )
                getAllArticlesForTheUser(
                  dateString?.[0],
                  dateString?.[1],
                  filters?.sort,
                )
              } else {
                setFilters(
                  Object.assign({}, filters, {
                    dateRange: [
                      dayjs(moment()),
                      dayjs(moment().add(1, 'days')),
                    ],
                  }),
                )
                getAllArticlesForTheUser(today, tomorrow, filters?.sort)
              }
            }}
            value={filters?.dateRange}
            defaultValue={[dayjs(moment()), dayjs(moment().add(1, 'days'))]}
            />
            {userDetails?.roles?.includes('admin') && (
            <>
            <Select
              style={{ width: 80, marginRight: '15px' }}
              placeholder="Set Hour"
              value={hour} 
              onChange={(value) => {
                console.log("value",value);
              
              setHour(value); 
              }}
              allowClear
            >
              <Option value="00">00:00</Option>
              <Option value="01">01:00</Option>
              <Option value="02">02:00</Option>
              <Option value="03">03:00</Option>
              <Option value="04">04:00</Option>
              <Option value="05">05:00</Option>
              <Option value="06">06:00</Option>
              <Option value="07">07:00</Option>
              <Option value="08">08:00</Option>
              <Option value="09">09:00</Option>
              <Option value="10">10:00</Option>
              <Option value="11">11:00</Option>
              <Option value="12">12:00</Option>
              <Option value="13">13:00</Option>
              <Option value="14">14:00</Option>
              <Option value="15">15:00</Option>
              <Option value="16">16:00</Option>
              <Option value="17">17:00</Option>
              <Option value="18">18:00</Option>
              <Option value="19">19:00</Option>
              <Option value="20">20:00</Option>
              <Option value="21">21:00</Option>
              <Option value="22">22:00</Option>
              <Option value="23">23:00</Option>
            </Select>
            <Button
              onClick={() => {
                handleExportCSV()
              }}
              type="primary"
              icon={<ExportOutlined />}
              style={{ backgroundColor: '#000', marginLeft: '15px' }}
            >
              Export CSV
            </Button>
            </>
          )}
        </div>
        <Table
          columns={columns}
          onChange={() => {
            scrollToTop() // For smooth scrolling when the table is updated
          }}
          expandable={{
            expandedRowRender: (record) => {
              return (
                <div
                  style={{
                    paddingLeft: '15px',
                    paddingRight: '15px',
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: '60%',
                  }}
                >
                  {/* Expanded Row Content */}
                  {record?.storyIdea && (
                    <>
                      <h4>Story Idea</h4>
                      <p style={{ marginBottom: '15px' }}>
                        {record?.storyIdea}
                      </p>
                    </>
                  )}
                  {record?.significance_details && (
                    <>
                      <h4>Significance</h4>
                      <p style={{ marginBottom: '15px' }}>
                        {record?.significance_details}
                      </p>
                    </>
                  )}


                  {record?.reference_details && (
                    <>
                      <h4>Reference</h4>
                      <p style={{ marginBottom: '10px', whiteSpace: 'pre-line' }}>
                        {record?.reference_details.split(' ').map((word, index) => {
                          const isUrl = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i.test(word);

                          if (isUrl) {
                            return (
                              <div key={index} style={{ marginBottom: '5px' }}>
                                <a
                                  href={word}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ color: 'blue', textDecoration: 'underline' }}
                                >
                                  {word}
                                </a>
                              </div>
                            );
                          } else {
                            return <span key={index} style={{ marginRight: '5px' }}>{word}</span>;
                          }
                        })}
                      </p>
                    </>
                  )}


                  {record?.infographics === 'Yes' && (
                    <>
                      <h4>Infographics Idea</h4>
                      <p style={{ marginBottom: '15px' }}>
                        {record?.infographicsIdea}
                      </p>
                    </>
                  )}
                  {record?.reviewer_comment && (
                    <>
                      <h4>Reviewer's Comments</h4>
                      <p style={{ marginBottom: '15px' }}>
                        {record?.reviewer_comment}
                      </p>
                    </>
                  )}
                  {record?.comments && (
                    <>
                      <h4>Comment</h4>
                      <p style={{ marginBottom: '15px' }}>{record?.comments}</p>
                    </>
                  )}
                  {record?.status?.status === 'Published' &&
                    record?.article_link && (
                      <>
                        <h4>Article URL</h4>
                        <p style={{ marginBottom: '15px' }}>
                          {record?.comments}
                        </p>
                        <Tooltip title="Article">
                          <Button
                            style={{ marginBottom: '10px' }}
                            shape="circle"
                            icon={<LinkOutlined />}
                            onClick={() => {
                              window.open(record?.article_link, '_blank')
                            }}
                          />
                        </Tooltip>
                      </>
                    )}
                  <>
                    <h4>Created On</h4>
                    <p>{record?.date}</p>
                  </>
                  <>
                    < ImageUpload record={record} userDetails={userDetails} />
                  </>
                </div>
              )
            },
          }}
          dataSource={dataNew}
        />
      </Card>
      <Modal
        width={700}
        title={'Story Board'}
        open={isModalOpen}
        onOk={handleOk}
        confirmLoading={isLoadingAddArticle}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{
            span: 5,
          }}
          wrapperCol={{
            span: 20,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="category"
            label="Story Type"

            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select placeholder="Select a story category" value={selectedCategory} onChange={(value) => {
              setSelectedCategory(value)
            }} allowClear>
              <Option value="News">News</Option>
              <Option value="Daily Narrative">Daily Narrative</Option>
              <Option value="Long-form">Long-form</Option>
              <Option value="Newsletter">Newsletter</Option>
              <Option value="Brand content">Brand content</Option>
              <Option value="Event">Event</Option>
              <Option value="Video">Video</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Headline"
            name="headline"
            rules={[
              {
                required: true,
                message: 'Please enter your Headline!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          {['Daily Narrative', 'Long-form', 'Brand content']?.includes(
            selectedCategory,
          ) && (
              <Form.Item
                label="Story Idea"
                name="storyIdea"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your Idea!',
                  },
                  {
                    validator: validateWordCount, // Custom validator for word count
                  },
                ]}
              >
                <TextArea
                  rows={4}
                  placeholder="Explain the story idea in 30 to 50 words."
                />
              </Form.Item>
            )}
          <Form.Item name="date" label="Date" {...config}>
            <DatePicker format="YYYY-MM-DD" />
          </Form.Item>
          {['Daily Narrative', 'Long-form']?.includes(selectedCategory) && (
            <Form.Item
              label="Significance"
              name="significance_details"
              rules={[
                {
                  required: true,
                  message: `Please enter the significance`,
                },
              ]}
            >
              <TextArea
                rows={2}
                placeholder={`What's new and Why does the story matter now ? Explain the significant of the story.`}
              />
            </Form.Item>
          )}
          {['Daily Narrative', 'Long-form']?.includes(selectedCategory) && (
            <Form.Item
              label="Reference"
              name="reference_details"
              rules={[
                {
                  required: true,
                  message: `Please enter the significance`,
                },
              ]}
            >
              <TextArea
                rows={2}
                placeholder={`Has any of this appeared elsewhere? If yes, please attach reference links of published articles.`}
              />
            </Form.Item>
          )}

          {['Daily Narrative', 'Long-form', 'Brand content']?.includes(
            selectedCategory,
          ) && (
              <Form.Item
                name="infographics"
                label="Infographics"
                initialValue="yes"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  placeholder="Select an option"
                  onChange={(e) => {
                    if (e === 'yes') {
                      setShowInfoGraphics(true);
                    } else {
                      setShowInfoGraphics(false);
                    }
                  }}
                  allowClear
                >
                  <Option value="yes">Yes</Option>
                  <Option value="no">No</Option>
                </Select>
              </Form.Item>

            )}

          {showInfoGraphics && (
            <Form.Item
              label="Infographics Idea"
              name="infographicsIdea"
              rules={[
                {
                  required: true,
                  message: 'Please enter infographic ideas!',
                },
              ]}
            >
              <TextArea rows={2} placeholder="" />
            </Form.Item>
          )}
          <Form.Item
            label="Comments"
            name="comments"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <TextArea rows={2} placeholder="" />
          </Form.Item>
        </Form>
        {!isEverythingUnique && (
          <p
            style={{ color: 'red', textAlign: 'center', padding: 0, margin: 0 }}
          >
            No two fields can contain the same value.
          </p>
        )}
      </Modal>
      <Modal
        title="Confirmation"
        open={showDeleteConfirmation}
        onOk={onDeleteConfirm}
        onCancel={handleHideDeleteConfirmation}
        okText="Delete"
        cancelText="Cancel"
        confirmLoading={isLoadingDeleteArticle}
      >
        Are you sure you want to delete this Article?
      </Modal>

      <Modal
        title="Published URL"
        open={showPublishedArticleLink}
        onOk={handlePublish}
        onCancel={handleHidePublishedArticleLink}
        okText="Confirm"
        cancelText="Cancel"
        confirmLoading={isLoadingDeleteArticle}
        okButtonProps={{ disabled: articleUrl ? false : true }}
      >
        <Input
          value={articleUrl}
          onChange={(e) => {
            setArticleUrl(e?.target?.value)
          }}
          placeholder="Enter the URL for your Article."
        />
      </Modal>
      <Modal
        title="Reviewer's Comment"
        open={showReviewersComment}
        onOk={handleApproveOrReject}
        onCancel={handleHideReviewersCommentModal}
        okText="Confirm"
        cancelText="Cancel"
        confirmLoading={isLoadingDeleteArticle}
        okButtonProps={{ disabled: reviewersComment ? false : true }}
      >
        <Input
          onChange={(e) => {
            setReviewersComment(e?.target?.value)
          }}
          placeholder="Provide your review comments"
        />
      </Modal>
      {userDetails?.email !== "siddharth.jindal@analyticsindiamag.com" && (
          <>
            <Title style={{ marginTop: '50px' }} level={4}>
              Stories Summary
            </Title>
            <Card>
              <Table columns={SumarryColumns} dataSource={summaryData} />
            </Card>

            <Title style={{ marginTop: '50px' }} level={4}>
              Edited Summary
            </Title>
            <Card>
              <Table columns={SumarryColumnsEdited} dataSource={EditedSummary} />
            </Card>

            <Title style={{ marginTop: '50px' }} level={4}>
              Published Summary
            </Title>
            <Card>
              <Table columns={SumarryColumnsCategory} dataSource={publishSummaryData} />
            </Card>
          </>
        )}

    </div>
  )
}

export default ArticleHistory